import "@fontsource/open-sans"
import "@fontsource/open-sans/300.css"
import "@fontsource/open-sans/500.css"
import "@fontsource/open-sans/600.css"
import "@fontsource/open-sans/700.css"
import "./src/styles/global.css"
import React from "react"
import { ApolloProvider } from "react-apollo"
import { client } from "./src/context/ApolloContext"

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
)
